<template>
  <div class="auth-section">
    <va-progress-bar v-if="loading"
      indeterminate
      :style="{width: '100%', position: 'absolute', bottom: 0, left: 0, backgroundColor: '#ffffff'}"></va-progress-bar>
    <div class="auth-title">Recover password</div>
    <form @submit.prevent="onsubmit" class="login ttl-form">
      <va-input
        v-model="username"
        type="text"
        :placeholder="$t(loginLabel)"
        :error="loginError"
        :autosize="false"
      />
      <div class="btns">
        <va-button type="submit" class="my-0 btn--caps" large :disabled="loading">{{ $t('auth.reset_password') }}</va-button>
        <va-button type="submit" class="my-0 btn--caps btn--white" large>
          <router-link :to="{name: 'login'}">
            {{ $t('auth.login') }}
          </router-link>
        </va-button>
      </div>
    </form>
    <va-modal
      v-model="showInfoModal"
      :title=" $t('We sent message to your E-mail') "
      cancelClass="none"
      :message=" $t('modal.recover.success.subtitle') "
      noOutsideDismiss
      noEscDismiss
      :cancel-text="null"
      ok-text="OK"
      @ok="onOk"
    />
    <va-modal
      v-model="showErrorModal"
      :title=" $t('Error') "
      cancelClass="none"
      okText="OK"
      :message=" $t('modal.recover.error.subtitle') "
      :cancel-text="null"
      noOutsideDismiss
      noEscDismiss
    />
  </div>
</template>

<script>
  import API from '../../../services/API/API';

  export default {
    name: 'recover-password',
    data() {
      return {
        username: '',
        loginLabel: 'Login or email',
        loginError: false,
        showInfoModal: false,
        showErrorModal: false,
        loading: false,
      };
    },
    methods: {
      onOk() {
        this.$router.push('/auth/login');
      },
      onsubmit() {
        this.loading = true;
        if (!this.username) {
          this.loginError = true;
          this.loginLabel = 'Login is required';
          this.loading = false;
        } else {
          API.APIPost(`${process.env.VUE_APP_API_URL}/auth/forgot-password`, {
            username: this.username,
          }, () => {
            this.loginError = false;
            this.loginLabel = 'auth.login';
            this.showInfoModal = true;
            this.loading = false;
          }, () => {
            this.showErrorModal = true;
            this.loginError = true;
            this.loginLabel = 'Incorrect login';
            this.loading = false;
          }, {}, true);
        }
      },
    },
  };
</script>

<style lang="scss">
  .auth-section {
    .va-progress-bar {
      bottom: auto !important;
      top: 0;
    }
  }

  .btns {
    margin-top: 32px;

    button {
      margin-right: 24px;
    }
  }
</style>
